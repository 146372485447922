import {BrowserRouter, Routes, Route} from 'react-router-dom';
import NoPage from './pages/no_page/NoPage';
import ConfirmPage from './pages/confirm/ConfirmPage';
import HomePage from './pages/home_page/HomePage';

export default function App() {
  return (
    <div>
      <BrowserRouter>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path='/helpaccountverify.com' element={<ConfirmPage />} />
        <Route path='*' element={<NoPage/>} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}
