import React, { useEffect } from "react";

const NoPage = () => {
  useEffect(() => {
    document.title = "My Page";
  }, []);

  return <div></div>;
};

export default NoPage;
