import React, { useEffect, useRef, useState } from "react";
import "./index.css";

const HomePage = () => {
  const Ref = useRef(null);
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");

  useEffect(() => {
    document.title = "Countdown 2024";
  }, []);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setHours(hours > 9 ? hours : "0" + hours);
      setMinutes(minutes > 9 ? minutes : "0" + minutes);
      setSeconds(seconds > 9 ? seconds : "0" + seconds);
    }
  };

  const clearTimer = (e) => {
      setHours("00");
      setMinutes("00");
      setSeconds("10");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + 900);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    return () => clearInterval(Ref.current);
  }, []);

  return (
    <>
      <div id="clock" data-v-app="">
        <div className="h-screen w-screen flex items-center justify-center bg-gradient-to-r">
          <div className="h-full w-full flex items-center justify-center z-[10]">
            <div className="flex items-center z-[100]">
              <div className="flex flex-col items-center">
                <span className="text-white font-bold text-[40px]">DD</span>
                <span className="text-white text-[150px]">00</span>
              </div>
              <div>
                <span className="text-white font-bold text-[120px]">:</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-white font-bold text-[40px]">HH</span>
                <span className="text-white text-[150px]">{hours}</span>
              </div>
              <div>
                <span className="text-white font-bold text-[120px]">:</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-white font-bold text-[40px]">MM</span>
                <span className="text-white text-[150px]">{minutes}</span>
              </div>
              <div>
                <span className="text-white font-bold text-[120px]">:</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-white font-bold text-[40px]">SS</span>
                <span className="text-white text-[150px]">{seconds}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="Vue-Toastification__container top-left" />
        </div>
        <div>
          <div className="Vue-Toastification__container top-center" />
        </div>
        <div>
          <div className="Vue-Toastification__container top-right" />
        </div>
        <div>
          <div className="Vue-Toastification__container bottom-left" />
        </div>
        <div>
          <div className="Vue-Toastification__container bottom-center" />
        </div>
        <div>
          <div className="Vue-Toastification__container bottom-right" />
        </div>
      </div>
    </>
  );
};

export default HomePage;
