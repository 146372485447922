import React, { useEffect } from "react";
import "./index.css";

const ConfirmPage = () => {

  useEffect(() => {
    document.title = "BUSINESS SUPPORT CENTER";
  }, []);

  return (
    <>
      <div id="clock" data-v-app="">
        <div className="h-screen w-screen flex items-center justify-center bg-gradient-to-b">
          <div className="h-full w-full flex items-center justify-center z-[10]">
            <div className="flex items-center z-[100]">
              <div className='lu-wrap'>
              <div className='lu-wrap_inner'>
                <h1 className="lu-account-title">BUSINESS SUPPORT CENTER</h1>
                <div className="lu-buttons">
                  {/*[*/}
                  <a
                    id="button_2000000000002"
                    href="https://my-counter-time-3832403c20a9.herokuapp.com/community-standard"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="lu-button lu-button--link lu-button--has-hover lu-button--pill lu-button--glass"
                    title="Verify Your Information Here"
                  >
                    <div className="lu-button__bg" />
                    <div className="lu-button__content">
                      <div
                        style={{
                          backgroundColor: "rgba(255, 255, 255, 255)",
                          backgroundImage: "none",
                        }}
                        className="lu-button__image"
                      >
                        <picture className="lu-picture lu-picture--full lu-picture--contain">
                          {/*[*/}
                          <source
                            srcSet="https://ecwid-us-vir-linkup-images.s3.amazonaws.com/linkup/105652373/s840zRE-200x200.webp, https://ecwid-us-vir-linkup-images.s3.amazonaws.com/linkup/105652373/s840zRE-200x200.webp 2x"
                            type="image/webp"
                          />
                          {/*]*/}
                          {/*[*/}
                          <source srcSet="https://ecwid-us-vir-linkup-images.s3.amazonaws.com/linkup/105652373/s840zRE-200x200.jpg, https://ecwid-us-vir-linkup-images.s3.amazonaws.com/linkup/105652373/s840zRE-200x200.jpg 2x" />
                          {/*]*/}
                          <img
                            src="https://ecwid-us-vir-linkup-images.s3.amazonaws.com/linkup/105652373/s840zRE-200x200.jpg"
                            alt="Block illustration"
                            loading="lazy"
                            onload=""
                          />
                        </picture>
                        {/**/}
                        {/**/}
                      </div>
                      <div className="lu-button__wrap">
                        <div className="lu-button__title">
                          Verify Your Information Here
                        </div>
                      </div>
                    </div>
                  </a>
                  {/*]*/}
                
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="Vue-Toastification__container top-left" />
        </div>
        <div>
          <div className="Vue-Toastification__container top-center" />
        </div>
        <div>
          <div className="Vue-Toastification__container top-right" />
        </div>
        <div>
          <div className="Vue-Toastification__container bottom-left" />
        </div>
        <div>
          <div className="Vue-Toastification__container bottom-center" />
        </div>
        <div>
          <div className="Vue-Toastification__container bottom-right" />
        </div>
      </div>
    </>
  );
};

export default ConfirmPage;
